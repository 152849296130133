export enum MeetingStatus {
  Scheduled = 'Scheduled',
  Cancelled = 'Cancelled',
  NoShow = 'No-show',
  Done = 'Done',
  Passed = 'Passed',
  Failed = 'Failed',
}

export enum MeetingType {
  IntroCall = 'IntroCall',
  TechnicalInterview = 'TechnicalInterview',
  TeamLeadIntro = 'TeamLeadIntro',
  ClientInterview = 'ClientInterview',
}

export const MeetingTypeLabel: Record<MeetingType, string> = {
  [MeetingType.IntroCall]: 'Intro Call',
  [MeetingType.TechnicalInterview]: 'Technical Interview',
  [MeetingType.TeamLeadIntro]: 'Team Lead Intro',
  [MeetingType.ClientInterview]: 'Client Interview',
};

export enum MeetingResolution {
  Passed = 'Passed',
  Failed = 'Failed',
}

export const MeetingResolutionOptions: MeetingResolution[] =
  Object.values(MeetingResolution);

export const DefaultEmailRejectionSubject =
  'Your interview with AgileEngine talent executive is cancelled';

export const DefaultEmailRejectionContent = (name: string) =>
  `${name},\n\nThank you for your interest in AgileEngine!\n\nWe have good and not-so-good news for you. The not-so-good news is that unfortunately our current projects don't appear to match your profile.\n\nAs for the good news, we are still keen to see you join AgileEngine later and we may reach out to you once we have something exciting.\n\nHave a great day!`;
