import {
  CandidateProfileEditFormValues,
  CandidateProfileEditPayload,
  IZohoCandidate,
  ZohoTask,
} from '@types';
import { removeUndefinedAttributes } from './object';
import {
  differenceInDays,
  differenceInYears,
  format,
  parse,
  subYears,
} from 'date-fns';
import { CandidateStatus, LocationDetailsByCountry } from '@constants';
import { isNumber, isUndefined } from 'lodash';

export const getInitialCandidateProfileEditFormValues = (
  candidateDetails: IZohoCandidate,
): CandidateProfileEditFormValues => {
  const yearsOfExperience = candidateDetails.Seniority_Start_Date
    ? differenceInYears(
        new Date(),
        new Date(candidateDetails.Seniority_Start_Date),
      )
    : null;

  return {
    Email: candidateDetails.Email,
    First_Name: candidateDetails.First_Name,
    Last_Name: candidateDetails.Last_Name,
    Location_Country: candidateDetails.Location_Country,
    Location_City: candidateDetails.Location_City,
    English_level: candidateDetails.English_level,
    'Dev_-_QA-AQA': candidateDetails['Dev_-_QA-AQA'],
    Technical_Flow: candidateDetails.Technical_Flow,
    Primary_Skill_Set: candidateDetails.Primary_Skill_Set,
    Secondary_Skill_Set: candidateDetails.Secondary_Skill_Set,
    experience: yearsOfExperience,
    Seniority_Level: candidateDetails.Seniority_Level,
    Hourly_Rate_Expected: candidateDetails.Hourly_Rate_Expected,
    Hourly_Rate_Current: candidateDetails.Hourly_Rate_Current,
    Notice_period_from_Offer: candidateDetails.Notice_period_from_Offer,
    Do_not_touch_Russia: candidateDetails.Do_not_touch_Russia,
    Phone: candidateDetails.Phone,
    Telegram: candidateDetails.Telegram,
    LinkedIn_URL: candidateDetails.LinkedIn_URL,
    AE_Formatted_CV_Link: candidateDetails.AE_Formatted_CV_Link,
    resumeFileName: candidateDetails.CV_File_Name,
    resume: null,
  };
};

export const transformCandidateProfileEditFormValuesToPayload = (
  formData: CandidateProfileEditFormValues,
  updatedFields: Partial<CandidateProfileEditFormValues>,
): Partial<CandidateProfileEditPayload> => {
  const isLocationChanged =
    !isUndefined(updatedFields.Location_City) ||
    !isUndefined(updatedFields.Location_Country);
  const city = updatedFields.Location_City || formData.Location_City;
  const country = updatedFields.Location_Country || formData.Location_Country;

  return removeUndefinedAttributes({
    Email: updatedFields.Email,
    First_Name: updatedFields.First_Name,
    Last_Name: updatedFields.Last_Name,
    Location_City: updatedFields.Location_City,
    Location_Country: updatedFields.Location_Country,
    New_Location: isLocationChanged
      ? city === 'Other'
        ? country
        : city
      : undefined,
    Location_Area:
      isLocationChanged && country
        ? LocationDetailsByCountry[country]?.area
        : undefined,
    Time_zone:
      isLocationChanged && country
        ? LocationDetailsByCountry[country]?.timezone
        : undefined,
    'Dev_-_QA-AQA': updatedFields['Dev_-_QA-AQA'],
    Technical_Flow: updatedFields.Technical_Flow,
    Primary_Skill_Set: updatedFields.Primary_Skill_Set,
    Secondary_Skill_Set: updatedFields.Secondary_Skill_Set,
    English_level: updatedFields.English_level,
    Seniority_Level: updatedFields.Seniority_Level,
    Seniority_Start_Date: isNumber(updatedFields.experience)
      ? format(subYears(new Date(), updatedFields.experience), 'yyyy-MM-01')
      : undefined,
    Hourly_Rate_Expected: updatedFields.Hourly_Rate_Expected,
    Hourly_Rate_Current: updatedFields.Hourly_Rate_Current,
    Notice_period_from_Offer: updatedFields.Notice_period_from_Offer,
    Do_not_touch_Russia: updatedFields.Do_not_touch_Russia,
    Phone: updatedFields.Phone,
    Telegram: updatedFields.Telegram,
    LinkedIn_URL: updatedFields.LinkedIn_URL,
    AE_Formatted_CV_Link: updatedFields.AE_Formatted_CV_Link,
  });
};

export const checkIfCandidateIsClosed = (
  candidate: IZohoCandidate,
): boolean => {
  return !!(
    (candidate.Lead_month_2018 &&
      candidate.Date_of_lead_closing &&
      candidate.Lead_closed_because_NEW !== 'Intro call no-show') ||
    candidate?.Company_do_not_touch?.length ||
    candidate?.Do_not_touch_Russia
  );
};

export const checkIfCandidateIsInProcess = (
  candidate: IZohoCandidate,
): boolean => {
  const processPeriodDays = 60;

  return (
    !!candidate?.Lead_month_2018 &&
    differenceInDays(
      new Date(),
      parse(
        candidate.Lead_month_2018.split(' ').slice(1).join(' '),
        'MMMM yyyy',
        new Date(),
      ),
    ) <= processPeriodDays
  );
};

export const chackIfCandidateFromMarketing = (
  candidate: IZohoCandidate,
): boolean => {
  if (!candidate || !candidate.LeadGen_owner) return false;

  return (
    !candidate.LeadGen_owner.toLowerCase().includes('launchpod') &&
    candidate.LeadGen_owner !== 'Mariia Havrylenko'
  );
};

export const generateZohoTask = (
  candidate: IZohoCandidate,
  status: CandidateStatus,
): ZohoTask | null => {
  const subject =
    status === CandidateStatus.IC_No_Show
      ? 'Call was missed by candidate. Re - scheduled? - FU 1'
      : status === CandidateStatus.IC_Canceled_By_Candidate
      ? 'Subject Call was CANCELLED by candidate Re - scheduled? FU 1'
      : null;

  if (!subject) return null;

  return {
    Subject: subject,
    Owner: candidate.LeadGen_owner!,
    Priority: 'High',
    Due_Date: format(new Date(), 'yyyy-MM-dd'),
    Context: 'Follow up 1',
    What_Id: candidate.id,
    $se_module: 'Candidates',
  };
};
