import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Flex, CandidateField } from '@components';

import {
  candidateDetailsSelectors,
  changeCandidateStatus,
} from '@redux/candidateDetails';

import {
  transormCandidateStatus,
  chackIfCandidateFromMarketing,
  generateZohoTask,
  isStatusLoading,
} from '@utils';
import { CandidateStatusFormValues, IZohoCandidate } from '@types';
import {
  CandidateStatus,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  DefaultEmailRejectionContent,
  DefaultEmailRejectionSubject,
  MeetingType,
  MeetingTypeLabel,
} from '@constants';

import { meetingRejectionValidationSchema } from './validationSchema';

const CancelledOptions = [
  CandidateStatus.IC_Canceled_By_Candidate,
  CandidateStatus.IC_Canceled_By_Recruiter,
  CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
  CandidateStatus.IC_Rejected_By_Recruiter_Location,
  CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
  CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
];

type FormData = {
  selectedOption: CandidateStatus;
  leadClosedBecause: string;
  recruiterFeedback: string;
  sendFollowUps: boolean;
  createZohoTask: boolean;
  rejectionSubject: string;
  rejectionEmail: string;
};

export const MeetingCancelModal: React.FC<{
  candidate: IZohoCandidate;
  meetingType: MeetingType;
  isOpen: boolean;
  onClose: () => void;
}> = ({ candidate, meetingType, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const { updateStatusAPIStatus } = useAppSelector(
    candidateDetailsSelectors.getUpdateCandidateStatusAPIData,
  );
  const isCandidateFromMarketing = chackIfCandidateFromMarketing(candidate);

  const formik = useFormik<FormData>({
    initialValues: {
      selectedOption: CandidateStatus.IC_Canceled_By_Candidate,
      leadClosedBecause: '',
      recruiterFeedback: '',
      sendFollowUps: true,
      createZohoTask: true,
      rejectionSubject: DefaultEmailRejectionSubject,
      rejectionEmail: DefaultEmailRejectionContent(
        candidate.First_Name || candidate.English_First_Name || 'NAME',
      ),
    },
    validationSchema: meetingRejectionValidationSchema,
    onSubmit: async (values) => {
      const getFormData = CandidateStatusFormConfig[values.selectedOption]!;
      const formValues = {
        Lead_closed_because_NEW: values.leadClosedBecause,
        Recruiters_Feedback: values.recruiterFeedback,
      } as CandidateStatusFormValues;
      const { formFieldsNames, autoupdatedFields } = getFormData(
        CandidateStatusStage.IC,
        formValues,
      );

      const isRejection = [
        CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
        CandidateStatus.IC_Rejected_By_Recruiter_Location,
        CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
        CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
      ].includes(values.selectedOption);

      const res = await dispatch(
        changeCandidateStatus({
          candidateId: candidate.id,
          payload: {
            transitions: [
              [
                {
                  stage: CandidateStatusStage.IC,
                  status: values.selectedOption,
                  jobOpeningId: null,
                },
              ],
            ],
            statusesData: [
              {
                stage: CandidateStatusStage.IC,
                status: values.selectedOption,
                Stage: CandidateStatusStage.IC,
                ...(formFieldsNames || []).reduce(
                  (acc, fieldName) => ({
                    ...acc,
                    [fieldName]: formValues[fieldName],
                  }),
                  {},
                ),
                ...autoupdatedFields,
              },
            ],
            notify: {
              defaultFollowUps: !isRejection && values.sendFollowUps,
              emailSubject: isRejection ? values.rejectionSubject : null,
              emailContent: isRejection ? values.rejectionEmail : null,
            },
            zohoTask:
              isCandidateFromMarketing && values.createZohoTask
                ? generateZohoTask(candidate, values.selectedOption)
                : null,
          },
        }),
      );

      if (!res?.type?.includes('rejected')) {
        onClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">
            {MeetingTypeLabel[meetingType]} cancelled
          </Typography>
          <CloseIcon
            onClick={() => onClose()}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '16px 24px', paddingTop: '16px !important' }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography>Reason</Typography>
            <RadioGroup
              value={formik.values.selectedOption}
              onChange={(e) =>
                formik.setFieldValue('selectedOption', e.target.value)
              }
              sx={{ gap: 2 }}
            >
              {CancelledOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio disableRipple />}
                  label={transormCandidateStatus(option)}
                />
              ))}
            </RadioGroup>
          </Box>
          {[CandidateStatus.IC_Canceled_By_Recruiter].includes(
            formik.values.selectedOption,
          ) && (
            <CandidateField
              field="Lead_closed_because_NEW"
              name="leadClosedBecause"
              required
              touched={formik.touched.leadClosedBecause}
              error={formik.errors.leadClosedBecause}
              value={formik.values.leadClosedBecause}
              onChange={(value) =>
                formik.setFieldValue('leadClosedBecause', value || '')
              }
              onBlur={() => formik.setFieldTouched('leadClosedBecause')}
            />
          )}
          {[
            CandidateStatus.IC_Canceled_By_Recruiter,
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
          ].includes(formik.values.selectedOption) && (
            <CandidateField
              field="Recruiters_Feedback"
              name="recruiterFeedback"
              touched={formik.touched.recruiterFeedback}
              error={formik.errors.recruiterFeedback}
              value={formik.values.recruiterFeedback}
              onChange={(value) =>
                formik.setFieldValue('recruiterFeedback', value || '')
              }
              onBlur={() => formik.setFieldTouched('recruiterFeedback')}
            />
          )}
          {[
            CandidateStatus.IC_Canceled_By_Candidate,
            CandidateStatus.IC_Canceled_By_Recruiter,
          ].includes(formik.values.selectedOption) && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.sendFollowUps}
                    onChange={(_, val) =>
                      formik.setFieldValue('sendFollowUps', val)
                    }
                  />
                }
                label="Send email followups with a Calendly link to reschedule"
              />
            </>
          )}
          {[CandidateStatus.IC_Canceled_By_Candidate].includes(
            formik.values.selectedOption,
          ) &&
            isCandidateFromMarketing && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.createZohoTask}
                    onChange={(_, val) =>
                      formik.setFieldValue('createZohoTask', val)
                    }
                  />
                }
                label=" Сreate a Zoho’s To-Do task for the LeadGen Owner to follow up with the
                  candidate and ensure the call is booked"
              />
            )}
          {[
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
          ].includes(formik.values.selectedOption) && (
            <>
              <Alert
                variant="standard"
                severity="info"
                icon={<MailOutlineIcon />}
              >
                Candidate will receive one time rejection email. Feel free to
                adjust the message according to the situation:
              </Alert>
              <TextField
                name="rejectionSubject"
                label="Subject"
                variant="outlined"
                fullWidth
                value={formik.values.rejectionSubject}
                required
                onChange={(e) =>
                  formik.setFieldValue('rejectionSubject', e.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.rejectionSubject &&
                  !!formik.errors.rejectionSubject
                }
                helperText={
                  formik.touched.rejectionSubject
                    ? formik.errors.rejectionSubject
                    : undefined
                }
              />
              <TextField
                name="rejectionEmail"
                label="Rejection email"
                variant="outlined"
                fullWidth
                value={formik.values.rejectionEmail}
                required
                onChange={(e) =>
                  formik.setFieldValue('rejectionEmail', e.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.rejectionEmail &&
                  !!formik.errors.rejectionEmail
                }
                helperText={
                  formik.touched.rejectionEmail
                    ? formik.errors.rejectionEmail
                    : undefined
                }
                multiline
                minRows={6}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isStatusLoading(updateStatusAPIStatus)}
          >
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
