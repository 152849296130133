import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { AppRoutes, JobOpeningReasonOfClosing, SearchParams } from '@constants';
import {
  isStatusFailed,
  isStatusForbidden,
  checkUserCanOpenEditJobOpening,
} from '@utils';

import {
  clearJobOpeningDetails,
  fetchJobOpeningById,
  fetchJobOpeningCandidates,
  jobOpeningDetailsSelectors,
  putOnHoldJobOpening,
  reopenJobOpening,
} from '@redux/jobOpeningDetails';

import { authSelectors } from '@redux/auth';

import {
  ForbiddenView,
  JobOpeningCandidates,
  SplitButton,
  JobOpeningInfo,
  Flex,
} from '@components';

import { CloseJobOpeningModal } from './components/CloseJobOpeningModal';

export const JobOpeningDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const joAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );
  const joIsPerformingAction = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningIsPerformingAction,
  );
  const id = useAppSelector(jobOpeningDetailsSelectors.getJobOpeningDetailsId);
  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const profile = useAppSelector(authSelectors.getProfileData);
  const isClosed = ['Cancelled2', 'Filled'].includes(
    jobOpeningDetails?.Job_Opening_Status || '',
  );
  const isOnHold =
    jobOpeningDetails?.Job_Opening_Status === 'Cancelled2' &&
    jobOpeningDetails.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;

  const canEditJobOpening = !isClosed || isOnHold;

  const [isOpenClosePositionModal, setIsOpenClosePositionModal] =
    useState(false);

  const userHasPermissionsToEditJO = checkUserCanOpenEditJobOpening(profile);

  useEffect(() => {
    const fetchDetails = async () => {
      if (id) {
        await dispatch(fetchJobOpeningById(id));
        await dispatch(fetchJobOpeningCandidates(id));
      }
    };

    fetchDetails();

    return () => {
      if (id) {
        dispatch(clearJobOpeningDetails());
      }
    };
  }, [id]);

  const onCloseClosePositionModal = useCallback(
    () => setIsOpenClosePositionModal(false),
    [],
  );

  const editButtonOptions = useMemo(
    () => [
      {
        label: 'Edit position',
        onClick: () => navigate(`/${AppRoutes.JOB_OPENINGS}/${id}/edit`),
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: 'Close position',
        onClick: () => setIsOpenClosePositionModal(true),
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: isOnHold ? 'Reopen' : 'Put on hold',
        onClick: async () => {
          if (!id) return;
          if (isOnHold) {
            await dispatch(reopenJobOpening(id));
          } else {
            await dispatch(putOnHoldJobOpening(id));
          }
          navigate(`/${AppRoutes.JOB_OPENINGS}`);
        },
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: 'Duplicate position',
        onClick: () => {
          if (!id) return;
          navigate({
            pathname: `/${AppRoutes.JOB_OPENINGS}/create`,
            search: createSearchParams({
              [SearchParams.DUPLICATE_JOB_OPENING_ID]: id,
            }).toString(),
          });
        },
        disabled: !userHasPermissionsToEditJO,
      },
    ],
    [id, isOnHold, userHasPermissionsToEditJO],
  );

  if (isStatusFailed(joAPIStatus)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="h3">Job opening not found</Typography>
      </Box>
    );
  }

  if (isStatusForbidden(joAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Flex
      gap={5}
      alignItems="start"
      flexWrap="wrap"
      sx={{
        padding: {
          xs: '24px',
          lg: '24px 56px 24px 56px',
        },
      }}
    >
      <Flex flex={3} gap={1} flexDirection="column" alignItems="start">
        <Typography color="text.primary" variant="h4">
          Position details
        </Typography>
        <Box
          sx={(theme) => ({
            boxShadow: 'none',
            backgroundColor: theme.palette.background.backgroundPrimary,
            padding: 1,
            borderRadius: '8px',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              padding: '8px 24px 32px 24px',
            })}
          >
            <JobOpeningInfo />
            <CloseJobOpeningModal
              isOpen={isOpenClosePositionModal}
              onModalClose={onCloseClosePositionModal}
            />
            <SplitButton
              options={editButtonOptions}
              loading={joIsPerformingAction}
            />
          </Box>
        </Box>
      </Flex>

      <Box flex={4} overflow="scroll">
        <JobOpeningCandidates />
      </Box>
    </Flex>
  );
};
