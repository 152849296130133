import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import {
  getNotifyCandidateOption,
  chackIfCandidateFromMarketing,
} from '@utils';
import { CandidateStatusFormik, IZohoCandidate } from '@types';
import { NotifyCandidateOption } from '@constants';

export const CandidateNotifyForm: React.FC<{
  formik: CandidateStatusFormik;
  candidate: IZohoCandidate;
}> = ({ formik, candidate }) => {
  const notifyOption = getNotifyCandidateOption(formik.values);
  const isCandidateFromMarketing = chackIfCandidateFromMarketing(candidate);

  const Content =
    notifyOption === NotifyCandidateOption.Default ? (
      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.defaultFollowUps}
            onChange={(_, val) => formik.setFieldValue('defaultFollowUps', val)}
          />
        }
        label="Send email followups with a Calendly link to reschedule"
      />
    ) : notifyOption === NotifyCandidateOption.DefaultWithTask ? (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.defaultFollowUps}
              onChange={(_, val) =>
                formik.setFieldValue('defaultFollowUps', val)
              }
            />
          }
          label="Send email followups with a Calendly link to reschedule"
        />
        {isCandidateFromMarketing && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.createZohoTask}
                onChange={(_, val) =>
                  formik.setFieldValue('createZohoTask', val)
                }
              />
            }
            label=" Сreate a Zoho’s To-Do task for the LeadGen Owner to follow up with the
                  candidate and ensure the call is booked"
          />
        )}
      </>
    ) : notifyOption === NotifyCandidateOption.Custom ? (
      <Box display="flex" flexDirection="column" gap={2}>
        <Alert variant="standard" severity="info" icon={<MailOutlineIcon />}>
          Candidate will receive one time rejection email. Feel free to adjust
          the message according to the situation:
        </Alert>
        <TextField
          name="notifyEmailSubject"
          label="Subject"
          variant="outlined"
          fullWidth
          value={formik.values.notifyEmailSubject || ''}
          required
          onChange={(e) =>
            formik.setFieldValue('notifyEmailSubject', e.target.value || null)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.notifyEmailSubject &&
            !!formik.errors.notifyEmailSubject
          }
          helperText={
            formik.touched.notifyEmailSubject
              ? formik.errors.notifyEmailSubject
              : undefined
          }
        />
        <TextField
          name="notifyEmailContent"
          label="Rejection email"
          variant="outlined"
          fullWidth
          value={formik.values.notifyEmailContent || ''}
          required
          onChange={(e) =>
            formik.setFieldValue('notifyEmailContent', e.target.value || null)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.notifyEmailContent &&
            !!formik.errors.notifyEmailContent
          }
          helperText={
            formik.touched.notifyEmailContent
              ? formik.errors.notifyEmailContent
              : undefined
          }
          multiline
          minRows={6}
        />
      </Box>
    ) : null;

  if (!Content) return null;

  return (
    <Box
      width="100%"
      sx={{ background: '#FFF', padding: '16px', marginBottom: 2 }}
    >
      {Content}
    </Box>
  );
};
