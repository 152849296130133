import { useCallback, useState } from 'react';
import { useAppDispatch } from '@redux/hooks';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';

import {
  CandidateStatus,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  MeetingType,
  MeetingTypeLabel,
} from '@constants';

import { changeCandidateStatus } from '@redux/candidateDetails';

import { chackIfCandidateFromMarketing, generateZohoTask } from '@utils';
import { CandidateStatusFormValues, IZohoCandidate } from '@types';

export const MeetingNoShowModal: React.FC<{
  candidate: IZohoCandidate;
  meetingType: MeetingType;
  isOpen: boolean;
  onClose: () => void;
}> = ({ candidate, meetingType, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [sendFollowUps, setSendFollowUps] = useState(true);
  const [createZohoTask, setCreateZohoTask] = useState(true);

  const isCandidateFromMarketing = chackIfCandidateFromMarketing(candidate);

  const onSaveClick = useCallback(async () => {
    const getFormData = CandidateStatusFormConfig[CandidateStatus.IC_No_Show]!;
    const { autoupdatedFields } = getFormData(
      CandidateStatusStage.IC,
      {} as CandidateStatusFormValues,
    );

    const res = await dispatch(
      changeCandidateStatus({
        candidateId: candidate.id,
        payload: {
          transitions: [
            [
              {
                stage: CandidateStatusStage.IC,
                status: CandidateStatus.IC_No_Show,
                jobOpeningId: null,
              },
            ],
          ],
          statusesData: [
            {
              stage: CandidateStatusStage.IC,
              status: CandidateStatus.IC_No_Show,
              Stage: CandidateStatusStage.IC,
              ...autoupdatedFields,
            },
          ],
          notify: {
            defaultFollowUps: sendFollowUps,
            emailSubject: null,
            emailContent: null,
          },
          zohoTask:
            isCandidateFromMarketing && createZohoTask
              ? generateZohoTask(candidate, CandidateStatus.IC_No_Show)
              : null,
        },
      }),
    );

    if (!res?.type?.includes('rejected')) {
      onClose();
    }
  }, [candidate.id, sendFollowUps]);

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">
            {MeetingTypeLabel[meetingType]} no-show
          </Typography>
          <CloseIcon
            onClick={() => onClose()}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={sendFollowUps}
              onChange={(_, val) => setSendFollowUps(val)}
            />
          }
          label="Send email followups with a Calendly link to reschedule"
        />
        {isCandidateFromMarketing && (
          <FormControlLabel
            control={
              <Checkbox
                checked={createZohoTask}
                onChange={(_, val) => setCreateZohoTask(val)}
              />
            }
            label=" Сreate a Zoho’s To-Do task for the LeadGen Owner to follow up with the
                  candidate and ensure the call is booked"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSaveClick}>
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
