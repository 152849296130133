import React, { ReactNode, useState } from 'react';

import {
  AppBar,
  Box,
  Hidden,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  Toolbar,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { CustomButton, SearchInput, Tab, Tabs } from '@components';

import { SearchInputMode } from '@constants';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.accent,
  color: theme.palette.text.invertedPrimary,
  padding: '0.625rem',
  borderRadius: '0.5rem',
  '&:hover': {
    backgroundColor: alpha(theme.palette.brand.accent, 0.7),
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.25rem',
    minWidth: '15rem',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      padding: '0.375rem 1rem',
      fontSize: '0.875rem',
      lineHeight: '150%',
      '&.Mui-selected ': {
        backgroundColor: theme.palette.highlight.actionableHover,
      },
    },
  },
}));

export const EntityDetailsHeader: React.FC<{
  headerOptions?: {
    HeaderComponent: ReactNode;
    onBackButtonClick: () => void;
    showHeaderContentSmDown?: boolean;
  };
  tabOptions?: {
    selectedTab: { label: string; value: string };
    tabs: { label: string; value: string }[];
    onChange: (value: string) => void;
  };
  searchOptions?: {
    placeholder: string;
    onChange: (value: string | null) => void;
  };
}> = ({ headerOptions, tabOptions, searchOptions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleTabClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTabClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={(theme) => ({
          boxShadow: 'none',
          backgroundColor: theme.palette.background.backgroundPrimary,
        })}
      >
        <Toolbar
          sx={{
            padding: {
              xs: '0.75rem 1rem',
            },
            justifyContent: 'space-between',
            gap: '0.5rem',
          }}
        >
          <Stack gap="0.5rem" direction="row" flex="0 1 auto">
            {!!headerOptions?.onBackButtonClick && (
              <StyledIconButton
                size="large"
                aria-label="go back"
                onClick={headerOptions.onBackButtonClick}
              >
                <ArrowBackRoundedIcon />
              </StyledIconButton>
            )}
            <Hidden
              mdDown={headerOptions?.showHeaderContentSmDown ? false : true}
            >
              {headerOptions?.HeaderComponent}
            </Hidden>
          </Stack>
          {!!tabOptions && (
            <>
              <Hidden mdUp>
                <Box flex={1}>
                  <CustomButton
                    size="medium"
                    color="secondary"
                    label={tabOptions?.selectedTab.label}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                    onClick={handleTabClick}
                  />
                </Box>
              </Hidden>
              <Hidden mdDown>
                <Tabs
                  value={tabOptions.selectedTab.value}
                  onChange={(e, newValue) => tabOptions.onChange(newValue)}
                  sx={{ flex: '0 1 auto', margin: '0 auto' }}
                >
                  {tabOptions.tabs.map(({ label, value }) => {
                    return (
                      <Tab
                        key={`${value}-tab`}
                        id={`${value}-tab`}
                        label={label}
                        value={value}
                      />
                    );
                  })}
                </Tabs>
              </Hidden>
            </>
          )}
          {!!searchOptions && (
            <Stack
              sx={{
                maxWidth: '16rem',
                alignItems: 'flex-end',
                width: {
                  xs: 'auto',
                  lg: '100%',
                },
              }}
            >
              <SearchInput
                mode={SearchInputMode.COMPACT}
                placeholder={searchOptions.placeholder}
                isLoading={false}
                onChange={searchOptions.onChange}
              />
            </Stack>
          )}
        </Toolbar>
      </AppBar>
      {!!tabOptions && (
        <StyledMenu
          MenuListProps={{
            'aria-labelledby': 'tab',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleTabClose}
          onBlur={handleTabClose}
        >
          {tabOptions.tabs.map(({ label, value }) => {
            return (
              <MenuItem
                key={`${value}-tab`}
                id={`${value}-tab`}
                disableRipple
                selected={tabOptions.selectedTab.value === value}
                onClick={() => tabOptions.onChange(value)}
              >
                {label}
              </MenuItem>
            );
          })}
        </StyledMenu>
      )}
    </React.Fragment>
  );
};
