import { CandidateStatus } from '@constants';
import * as yup from 'yup';

export const meetingFeedbackValidationSchema = yup.object({
  doneDate: yup.string().required('Meeting done date is required'),
  resolution: yup.string().required('Meeting resolution is required'),
  feedback: yup.string().max(5000).required('Meeting feedback is required'),
});

export const meetingRejectionValidationSchema = yup.object({
  leadClosedBecause: yup
    .string()
    .optional()
    .max(255)
    .when(['selectedOption'], (formData, schema) => {
      const selectedOption: CandidateStatus = formData[0];

      if ([CandidateStatus.IC_Canceled_By_Recruiter].includes(selectedOption))
        return schema.required('Closing reason is required');

      return schema;
    }),
  recruiterFeedback: yup.string().optional().max(5000),
  rejectionSubject: yup
    .string()
    .optional()
    .max(255)
    .when(['selectedOption'], (formData, schema) => {
      const selectedOption: CandidateStatus = formData[0];

      if (
        [
          CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
          CandidateStatus.IC_Rejected_By_Recruiter_Location,
          CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
          CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
        ].includes(selectedOption)
      )
        return schema.required('Rejection subject is required');

      return schema;
    }),
  rejectionEmail: yup
    .string()
    .optional()
    .max(5000)
    .when(['selectedOption'], (formData, schema) => {
      const selectedOption: CandidateStatus = formData[0];

      if (
        [
          CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
          CandidateStatus.IC_Rejected_By_Recruiter_Location,
          CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
          CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
        ].includes(selectedOption)
      )
        return schema.required('Rejection email is required');

      return schema;
    }),
});
