import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';

import { useAppSelector } from '@redux/hooks';

import { jobOpeningDetailsSelectors } from '@redux/jobOpeningDetails';
import { JobOpeningFieldsLabels } from '@constants';
import { isStatusLoading } from '@utils';
import { CountryFlag, RowLayout } from '@components';
import { isNumber } from 'lodash';

export const JobOpeningInfo: React.FC = () => {
  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const joDetailsAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );

  const isLoadingApi = isStatusLoading(joDetailsAPIStatus);

  return (
    <Box sx={{ mt: 0.5, mb: 0.5 }}>
      <RowLayout
        label="Client"
        isEmpty={!jobOpeningDetails?.Client_Name_New}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Client_Name_New || '-'}
          </Typography>
        }
      />
      <RowLayout
        label="Hiring manager"
        isEmpty={!jobOpeningDetails?.Hiring_M}
        Component={
          <Stack direction={'row'} gap={1}>
            <Typography variant="body1">
              {jobOpeningDetails?.Hiring_M}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecoration: 'underline' }}
            >
              {jobOpeningDetails?.Hiring_Manager_email || 'No email'}
            </Typography>
          </Stack>
        }
      />
      <RowLayout
        label="PDM"
        isEmpty={!jobOpeningDetails?.PDM}
        Component={
          <Typography variant="body1">{jobOpeningDetails?.PDM}</Typography>
        }
      />
      <RowLayout
        label="Client recruiter"
        isEmpty={!jobOpeningDetails?.Responsible_Recruiter?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Responsible_Recruiter?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Interviewer"
        isEmpty={!jobOpeningDetails?.Technical_Reviewer}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Technical_Reviewer}
          </Typography>
        }
      />
      <RowLayout
        label="Additional Interviewer"
        isEmpty={!jobOpeningDetails?.Additional_Technical_Interviewer}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Additional_Technical_Interviewer}
          </Typography>
        }
      />
      <RowLayout
        label="Opened official"
        isEmpty={!jobOpeningDetails?.Date_Opened_Official}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Opened_Official}
          </Typography>
        }
      />
      <RowLayout
        label="Pre-opened"
        isEmpty={!jobOpeningDetails?.Date_Pre_opened}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Pre_opened}
          </Typography>
        }
      />
      <RowLayout
        label="Date opened"
        isEmpty={!jobOpeningDetails?.Date_Opened}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Opened}
          </Typography>
        }
      />
      <RowLayout
        label="Days since opening"
        isEmpty={!jobOpeningDetails?.Days_since_opening_new}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Days_since_opening_new}
          </Typography>
        }
      />
      <RowLayout
        label="Locations"
        isEmpty={!jobOpeningDetails?.Location_Area_Test?.length}
        Component={
          <>
            {jobOpeningDetails?.Location_Area_Test?.map((country: string) => (
              <CountryFlag country={country} key={country} />
            ))}
          </>
        }
      />
      <RowLayout
        label="Location tier outreach"
        isEmpty={!jobOpeningDetails?.Location_Tier_Outreach?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Location_Tier_Outreach?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Specialization"
        isEmpty={!jobOpeningDetails?.Specialization}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Specialization}
          </Typography>
        }
      />
      <RowLayout
        label="LP specializations"
        isEmpty={!jobOpeningDetails?.LP_Specializations?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.LP_Specializations?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Technical flow"
        isEmpty={!jobOpeningDetails?.Technical_Flow}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Technical_Flow}
          </Typography>
        }
      />
      <RowLayout
        label="Seniority"
        isEmpty={!jobOpeningDetails?.Seniority_level?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Seniority_level?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label={JobOpeningFieldsLabels.Interview_process}
        isEmpty={!jobOpeningDetails?.Interview_process}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Interview_process}
          </Typography>
        }
      />
      <RowLayout
        label="Available intro calls"
        isEmpty={isLoadingApi}
        Component={
          <>
            <Typography variant="body1">
              {isNumber(jobOpeningDetails?.maxCalls)
                ? `${jobOpeningDetails?.availableCalls}/${jobOpeningDetails?.maxCalls}`
                : 'No Limits'}
            </Typography>
          </>
        }
      />

      <Stack direction="row" alignItems="center" gap={2} mb={3} mt={2}>
        <Link
          href={`https://recruit.zoho.com/recruit/org30986285/EntityInfo.do?module=JobOpenings&id=${jobOpeningDetails?.id}&submodule=JobOpenings`}
          component={'a'}
          target="_blank"
          color="text.link"
          sx={{
            cursor: 'pointer',
            textWrap: 'nowrap',
          }}
        >
          JO at Zoho ↗
        </Link>
        {jobOpeningDetails?.Careers_site_link ? (
          <Link
            href={jobOpeningDetails.Careers_site_link}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            JO at Careers ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No JO on Careers site
          </Typography>
        )}
        {jobOpeningDetails?.Google_Drive_Folder ? (
          <Link
            href={jobOpeningDetails.Google_Drive_Folder}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            JD doc ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No JD document
          </Typography>
        )}
        {jobOpeningDetails?.TI_Requirements_Link ? (
          <Link
            href={jobOpeningDetails.TI_Requirements_Link}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            TI Requirements document ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No TI Requirements document
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
