import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { EntityDetailsHeader } from './components/Header';
import { LoadingSnackbar } from '@components';

export const EntityDetailsLayout: React.FC<{
  headerOptions?: {
    HeaderComponent: ReactNode;
    onBackButtonClick: () => void;
    showHeaderContentSmDown?: boolean;
  };
  tabOptions?: {
    selectedTab: { label: string; value: string };
    tabs: { label: string; value: string }[];
    onChange: (value: string) => void;
  };
  searchOptions?: {
    placeholder: string;
    onChange: (value: string | null) => void;
  };
  loading?: boolean;
  children: ReactNode;
}> = ({ headerOptions, tabOptions, searchOptions, loading, children }) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <EntityDetailsHeader
        headerOptions={headerOptions}
        tabOptions={tabOptions}
        searchOptions={searchOptions}
      />
      {loading && (
        <Box position={'absolute'} top={10} right={'50%'}>
          <LoadingSnackbar />
        </Box>
      )}
      <Box component={'main'} sx={{ flex: '1 1 auto' }}>
        {children}
      </Box>
    </Box>
  );
};
