import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import { getCountryCode } from '@utils';
import React from 'react';

const LatAmCountries = [
  'Mexico',
  'Argentina',
  'Colombia',
  'Brazil',
  'Guatemala',
];

export const CountryFlag: React.FC<{
  country?: string;
  sx?: SxProps<Theme>;
}> = ({ country, sx }) => {
  if (!country) return null;

  const includesUSA = country.includes('USA');

  const countries =
    country == 'LatAm' ? LatAmCountries : includesUSA ? ['USA'] : [country];

  return (
    <>
      {countries.map((country) =>
        getCountryCode(country) ? (
          <Tooltip title={country} placement="top" key={country}>
            <Box
              marginRight={'4px'}
              display={'inline-block'}
              key={country}
              sx={sx}
            >
              {country && (
                <img
                  loading="lazy"
                  width="16"
                  src={`https://flagcdn.com/w20/${getCountryCode(country)}.png`}
                  srcSet={`https://flagcdn.com/w40/${getCountryCode(
                    country,
                  )}.png 2x`}
                  alt={`${country} flag`}
                  style={{
                    borderRadius: '2px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,0.1)',
                  }}
                />
              )}
            </Box>
          </Tooltip>
        ) : (
          country
        ),
      )}
    </>
  );
};
