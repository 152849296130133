import { CandidateStage, CandidateStagesOrder } from '@constants';
import { IDWHCandidate } from '@types';

export const isCandidatePassedStage = (
  candidate: IDWHCandidate,
  stage: CandidateStage,
) =>
  CandidateStagesOrder.indexOf(candidate.candidateStage) >=
  CandidateStagesOrder.indexOf(stage);

export const getCandidatesByStage = (
  candidates: IDWHCandidate[],
  stage: CandidateStage,
) =>
  stage === CandidateStage.NEW_HOME
    ? candidates.filter(
        (candidate) =>
          candidate.Type_of_Inbound_Channel === 'New Home' &&
          candidate.New_Home_Date_Start &&
          !candidate.New_Home_Date_End,
      )
    : candidates?.filter((candidate) =>
        isCandidatePassedStage(candidate, stage),
      );
