import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch } from '@redux/hooks';

import { DialogActions } from '@mui/material';
import { Flex } from '@components';
import { FormLayout } from './common/FormLayout';
import { BaseCandidateStatusForm } from './common/BaseForm';
import { CandidateStatusFormActions } from './common/CandidateStatusFormActions';
import { CandidateNotifyForm } from './common/CandidateNotifyForm';

import { changeCandidateStatus } from '@redux/candidateDetails';

import {
  getInitialCandidateStatusFormValues,
  getTransitionForms,
  transformFormValuesToPayload,
} from '@utils';
import {
  CandidateStatusFormValues,
  IDWHJobOpening,
  IZohoCandidate,
} from '@types';
import {
  CandidateStatus,
  CandidateStatusStage,
  EnineeringSpecializations,
} from '@constants';

import { candidateStatusValidationSchema } from './validationSchema';

export const CandidateStatusForm: React.FC<{
  candidate: IZohoCandidate;
  selectedJobOpeningId: string | null;
  associatedJopOpenings: IDWHJobOpening[];
  preselectedStage: CandidateStatusStage | null;
  onCancel: () => void;
}> = ({
  candidate,
  associatedJopOpenings,
  selectedJobOpeningId,
  preselectedStage,
  onCancel,
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<CandidateStatusFormValues>({
    initialValues: getInitialCandidateStatusFormValues(
      candidate,
      selectedJobOpeningId,
      preselectedStage,
    ),
    validationSchema: candidateStatusValidationSchema,
    onSubmit: async (values) => {
      dispatch(
        changeCandidateStatus(
          transformFormValuesToPayload(
            values,
            candidate.id,
            candidate,
            associatedJopOpenings,
          ),
        ),
      );
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  useEffect(() => {
    const isEngineering =
      candidate['Dev_-_QA-AQA'] &&
      EnineeringSpecializations.includes(candidate['Dev_-_QA-AQA']);
    const nextTransitionForms = getTransitionForms(
      formik.values.transitions,
      candidate,
      associatedJopOpenings,
    );

    const excludedTransitions = [...formik.values.excludedTransitions];

    // Exclude VI statuses for Engineering candidates by default
    if (isEngineering) {
      [
        CandidateStatus.VI_Sent,
        CandidateStatus.VI_Done,
        CandidateStatus.VI_Passed,
      ].forEach((status) => {
        const existsInCurrentTransitionForms =
          formik.values.transitionForms.find(
            (tf) =>
              tf.status === status &&
              tf.stage === CandidateStatusStage.VideoInterview,
          );
        const existsInNextTransitionForms = nextTransitionForms.find(
          (tf) =>
            tf.status === status &&
            tf.stage === CandidateStatusStage.VideoInterview,
        );

        if (!existsInCurrentTransitionForms && existsInNextTransitionForms)
          excludedTransitions.push({
            stage: CandidateStatusStage.VideoInterview,
            status,
          });
      });
    }

    formik.setValues({
      ...formik.values,
      transitionForms: nextTransitionForms,
      excludedTransitions,
    });
  }, [formik.values.transitions, candidate['Dev_-_QA-AQA']]);

  return (
    <>
      <Flex flexDirection="column" gap={2} alignItems="start">
        <Flex flexDirection="column" gap={2} alignItems="start" px={3}>
          <BaseCandidateStatusForm
            formik={formik}
            candidate={candidate}
            associatedJopOpenings={associatedJopOpenings}
          />
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="start"
          sx={(theme) => ({
            background: theme.palette.highlight.sectionLight,
            px: 3,
          })}
        >
          {formik.values.transitionForms.map(({ stage, status }) => (
            <FormLayout
              key={status}
              stage={stage as CandidateStatusStage}
              status={status as CandidateStatus}
              formik={formik}
              candidateDetails={candidate}
            />
          ))}
          <CandidateNotifyForm formik={formik} candidate={candidate} />
        </Flex>
      </Flex>

      <DialogActions>
        <CandidateStatusFormActions
          formik={formik}
          associatedJopOpenings={associatedJopOpenings}
          onCancel={onCancel}
        />
      </DialogActions>
    </>
  );
};
