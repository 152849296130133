import { useCallback } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import { ICandidateSubmission } from '@types';

import { displayDateTimeInTimezone } from '@utils';
import { SubmissionStatus } from '@constants';

export const ClientFeeback: React.FC<{
  submission: ICandidateSubmission;
}> = ({ submission }) => {
  const getResolutionText = useCallback(() => {
    const resolution = submission.clientFeedback?.resolution;
    if (!resolution) return null;

    const isCallRequested = resolution === SubmissionStatus.CallRequested;
    const isRejected = resolution === SubmissionStatus.Rejected;

    if (isCallRequested) {
      return 'Approved for an interview ✓';
    }

    if (isRejected) {
      return (
        <>
          Rejected:{' '}
          <Box component="span" fontWeight={400}>
            {submission.clientFeedback?.rejectionReason?.join(', ')}
          </Box>{' '}
          ✗
        </>
      );
    }

    return resolution;
  }, [submission]);

  const getResolutionBgColor = useCallback(() => {
    return submission.clientFeedback?.resolution ===
      SubmissionStatus.CallRequested
      ? 'text.success'
      : 'text.secondary';
  }, [submission.clientFeedback?.resolution]);

  if (!submission.clientFeedback?.resolution) return null;

  return (
    <Stack
      sx={{
        padding: {
          xs: '1rem',
          sm: '0.75rem',
        },
        mx: {
          xs: '-1rem',
          sm: '-0.5rem',
        },
        mt: '-0.5rem',
      }}
    >
      <Typography variant="body1" mb={1}>
        Client feedback
      </Typography>
      <Box
        padding="0.875rem 1.5rem 1rem"
        borderRadius="0.25rem"
        bgcolor={getResolutionBgColor()}
      >
        <Typography variant="body2" color="common.white" fontWeight={600}>
          {getResolutionText()}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          background: theme.palette.background.warningFill,
          padding: '24px',
          borderRadius: '8px',
        })}
      >
        <Box display="flex" gap={2} flexDirection="column">
          {!!submission.clientFeedback.feedback && (
            <>
              <Typography variant="body2" color="text.secondary">
                Feedback
              </Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {submission.clientFeedback.feedback}
              </Typography>
            </>
          )}

          {!!submission.interview?.requestedSlot?.length && (
            <>
              <Typography variant="body2" color="text.secondary">
                Requested interview slots
              </Typography>
              {submission.interview.requestedSlot.map((slot) => (
                <Typography key={slot} variant="body1" color="text.primary">
                  {displayDateTimeInTimezone(
                    slot,
                    submission.interview!.clientTimezone!,
                  )}
                </Typography>
              ))}
            </>
          )}

          <Stack>
            <Typography variant="body1" color="text.primary">
              {submission.clientFeedback?.reviewer.name}
              <Box component={'span'} color={'text.secondary'}>
                {' ∙ '}
                {submission.clientFeedback?.reviewer.role},{' '}
                {submission.clientFeedback?.reviewer.company}
              </Box>
            </Typography>
            <Link
              color="text.secondary"
              href={`mailto:${submission.clientFeedback?.reviewer.email}`}
              fontSize="0.688rem"
            >
              {submission.clientFeedback?.reviewer.email}
            </Link>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
