import * as yup from 'yup';
import { mapValues } from 'lodash';

import {
  AutocompltereMultiselectCandidateFields,
  CandidateFieldsLabels,
  CandidateStatus,
  CandidateStatusStage,
  NumberfieldCandidateFields,
  RequiredCandidateStatusFieldsByStatus,
} from '@constants';
import {
  CandidateStatusFormFields,
  CandidateStatusFormExcludedTransition,
} from '@types';

const requiredFields: {
  [k in keyof CandidateStatusFormFields]?:
    | yup.ArraySchema<(string | undefined)[] | undefined, yup.AnyObject, '', ''>
    | yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>
    | yup.NumberSchema<number | null | undefined, yup.AnyObject, undefined, ''>;
} = mapValues(
  RequiredCandidateStatusFieldsByStatus,
  (
    transitions: {
      status: CandidateStatus;
      stage: CandidateStatusStage;
    }[],
    fieldName: keyof CandidateStatusFormFields,
  ) => {
    if (NumberfieldCandidateFields.includes(fieldName)) {
      return yup
        .number()
        .optional()
        .nullable()
        .when(
          ['transitionForms', 'excludedTransitions'],
          (formData, schema) => {
            const transitionForms: CandidateStatusFormExcludedTransition[] =
              formData[0];
            const excludedFormTransitions: CandidateStatusFormExcludedTransition[] =
              formData[1];

            const filteredFormTransitions = transitionForms.filter(
              (ts) =>
                !excludedFormTransitions.find(
                  (ets) => ets.status === ts.status && ets.stage === ts.stage,
                ),
            );

            const fieldLabel = CandidateFieldsLabels[fieldName];

            if (
              filteredFormTransitions.some((fts) =>
                transitions.find(
                  (ts) => ts.stage === fts.stage && ts.status === fts.status,
                ),
              )
            )
              return schema
                .nonNullable()
                .required(
                  fieldLabel ? `${fieldLabel} is required` : 'Required field',
                );
            return schema;
          },
        )
        .nullable();
    }

    if (AutocompltereMultiselectCandidateFields.includes(fieldName))
      return yup
        .array(yup.string())
        .when(
          ['transitionForms', 'excludedTransitions'],
          (formData, schema) => {
            const transitionForms: CandidateStatusFormExcludedTransition[] =
              formData[0];
            const excludedFormTransitions: CandidateStatusFormExcludedTransition[] =
              formData[1];

            const filteredFormTransitions = transitionForms.filter(
              (ts) =>
                !excludedFormTransitions.find(
                  (ets) => ets.status === ts.status && ets.stage === ts.stage,
                ),
            );

            if (
              filteredFormTransitions.some((fts) =>
                transitions.find(
                  (ts) => ts.stage === fts.stage && ts.status === fts.status,
                ),
              )
            )
              return schema
                .min(1, 'Select at least one value')
                .required('Select at least one value');
            return schema;
          },
        )
        .nullable();

    return yup
      .string()
      .optional()
      .when(['transitionForms', 'excludedTransitions'], (formData, schema) => {
        const transitionForms: CandidateStatusFormExcludedTransition[] =
          formData[0];
        const excludedFormTransitions: CandidateStatusFormExcludedTransition[] =
          formData[1];

        const filteredFormTransitions = transitionForms.filter(
          (ts) =>
            !excludedFormTransitions.find(
              (ets) => ets.status === ts.status && ets.stage === ts.stage,
            ),
        );

        const fieldLabel = CandidateFieldsLabels[fieldName];

        if (
          filteredFormTransitions.some((fts) =>
            transitions.find(
              (ts) => ts.stage === fts.stage && ts.status === fts.status,
            ),
          )
        )
          return schema.required(
            fieldLabel ? `${fieldLabel} is required` : 'Required field',
          );
        return schema;
      })
      .nullable();
  },
);

export const candidateStatusValidationSchema = yup.object({
  ...requiredFields,
  notifyEmailSubject: yup
    .string()
    .optional()
    .max(255)
    .when(['transitionForms', 'excludedTransitions'], (formData, schema) => {
      const transitionForms: CandidateStatusFormExcludedTransition[] =
        formData[0];
      const excludedFormTransitions: CandidateStatusFormExcludedTransition[] =
        formData[1];

      const filteredFormTransitions = transitionForms.filter(
        (ts) =>
          !excludedFormTransitions.find(
            (ets) => ets.status === ts.status && ets.stage === ts.stage,
          ),
      );

      if (
        filteredFormTransitions.some((fts) =>
          [
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
          ].includes(fts.status as CandidateStatus),
        )
      )
        return schema.required('Rejection subject is required');

      return schema;
    })
    .nullable(),
  notifyEmailContent: yup
    .string()
    .optional()
    .max(5000)
    .when(['transitionForms', 'excludedTransitions'], (formData, schema) => {
      const transitionForms: CandidateStatusFormExcludedTransition[] =
        formData[0];
      const excludedFormTransitions: CandidateStatusFormExcludedTransition[] =
        formData[1];

      const filteredFormTransitions = transitionForms.filter(
        (ts) =>
          !excludedFormTransitions.find(
            (ets) => ets.status === ts.status && ets.stage === ts.stage,
          ),
      );

      if (
        filteredFormTransitions.some((fts) =>
          [
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
          ].includes(fts.status as CandidateStatus),
        )
      )
        return schema.required('Rejection email is required');

      return schema;
    })
    .nullable(),
});
