import React, { ReactNode } from 'react';

import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

export const CandidateStages: React.FC<{
  loading: boolean;
  stages: {
    label: string;
    value: string;
    amount: number;
    amountIcon?: ReactNode;
  }[];
  activeStage: string | null;
  onClick: (value: string | null) => void;
}> = ({ loading, activeStage, stages, onClick }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {stages.map((stage) => (
              <TableCell
                key={`head-${stage.value}`}
                sx={(theme) => ({
                  borderBottom: 'none',
                  textAlign: 'center',
                  padding: '8px',
                  background: theme.palette.highlight.neutral,
                  textWrap: 'nowrap',
                  '&:first-of-type': {
                    borderRadius: '8px 0 0 8px',
                  },
                  '&:last-of-type': {
                    borderRadius: '0 8px 8px 0',
                  },
                })}
              >
                {stage.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {stages.map((stage) => (
              <TableCell
                key={`body-${stage.value}`}
                sx={{ borderBottom: 'none', padding: '8px' }}
              >
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={45}
                    width="100%"
                    sx={{
                      borderRadius: '8px',
                      padding: '8px',
                    }}
                  />
                ) : (
                  <Button
                    variant="text"
                    disabled={!stage.amount}
                    fullWidth
                    sx={(theme) => ({
                      borderRadius: 1,
                      padding: 0,
                      color:
                        activeStage === stage.value
                          ? theme.palette.text.brand
                          : 'rgba(0, 0, 0, 0.87)',
                      background:
                        activeStage === stage.value
                          ? theme.palette.background.brandLight
                          : !stage.amount
                          ? 'transparent'
                          : theme.palette.highlight.actionable,
                      fontWeight: 500,
                      textAlign: 'left',
                      fontSize: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    })}
                    onClick={
                      !stage.amount
                        ? undefined
                        : () =>
                            onClick(
                              activeStage === stage.value ? null : stage.value,
                            )
                    }
                  >
                    {stage.amount}
                    {stage.amountIcon}
                  </Button>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
