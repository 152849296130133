import { IDWHCandidate, IDWHCandidateRaw } from '@types';
import { startCase } from 'lodash';
import { LocalStorageService } from 'services/localStorageService';
const parseField = (field: string | null) =>
  !field
    ? []
    : field.startsWith('[') && field.endsWith(']')
    ? JSON.parse(field)
    : field
        .replace(/[{}]/g, '')
        .split(/[,;]+/)
        .map((e: string) => e.trim());

export const transformCandidate = (data: IDWHCandidateRaw): IDWHCandidate => {
  const parsedWasInterestedMonth = parseField(data.Was_interested_month);
  const parsedTestTaskStatus = parseField(data.Test_Task);
  const parsedPrimarySkills = parseField(data.Primary_Skill_Set);
  const parsedSecondarySkills = parseField(data.Secondary_Skill_Set);
  const parsedTestTaskReviewers = parseField(data.Test_Task_Reviewers);

  return {
    ...data,
    Was_interested_month: parsedWasInterestedMonth,
    Skill_Set: (data.Skill_Set || '')
      .split(/[,;]+/)
      .map((e: string) => e.trim())
      .filter(Boolean),
    Test_Task: parsedTestTaskStatus.filter(Boolean),
    Primary_Skill_Set: parsedPrimarySkills,
    Secondary_Skill_Set: parsedSecondarySkills,
    Test_Task_Reviewers: parsedTestTaskReviewers,
  };
};

export const getCandidateName = ({
  fullName,
  firstName,
  lastName,
  englishFirstName,
  englishLastName,
}: {
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  englishFirstName?: string | null;
  englishLastName?: string | null;
}): string => {
  if (
    !(fullName || firstName || lastName || englishFirstName || englishLastName)
  )
    return '';

  const fullNameToUse = fullName?.trim();
  if (fullNameToUse) return fullNameToUse;

  const firstNameToUse = firstName?.trim() || englishFirstName?.trim();
  const lastNameToUse = lastName?.trim() || englishLastName?.trim();

  if (firstNameToUse || lastNameToUse)
    return `${firstNameToUse} ${lastNameToUse}`;

  return '';
};

export const getNameFromEmail = (
  email?: string | null,
): { firstName: string; lastName: string } | null => {
  if (!email) return null;

  const emailPrefix = email.split('@')[0];

  const nameParts = emailPrefix.split('.');

  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  return {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
  };
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const transormCandidateStatus = (
  status: string | null | undefined,
): string => (!status ? '' : status.replace(/LP: /g, ''));

export const formatEmailMessage = (rawMessage: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let formattedMessage = rawMessage.replace(urlRegex, (url: string) => {
    if (url.includes('calendly.com/')) {
      LocalStorageService.setState('user_calendly_link', url);
    }
    return `<a style="color: #3F8CFF; text-decoration: underline;" href="${url}" target="_blank">${url}</a>`;
  });
  formattedMessage = formattedMessage.replace(/\n/g, '<br/>');
  return formattedMessage;
};
